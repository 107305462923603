import "./why.css";

export default function Why() {
  return (
    <>
      <div className="conatiner why pt-3 mt-3 overflow-hidden">
        <div className="row justify-content-evenly border-top flex-wrap">
          <div className="determine col-md-3 col-sm-12 mt-2 px-5 px-sm-5 px-md-0">
            <h2>Why Determining a Digital Marketing Strategy is a Must?</h2>
            <p>
              At Twinkle Media Hub, we understand that navigating the digital
              landscape can be daunting. That's why we emphasize the importance
              of a tailored digital marketing strategy. It's not just about
              visibility; it's about crafting meaningful connections with your
              audience. We'll work closely with you to uncover insights, set
              clear goals, and pave the way for your digital success.
            </p>
          </div>
          <div className="determine col-md-4 border-start border-end  col-sm-12 px-5 mt-2">
            <h2>WHY TWINKLE MEDIA HUB SERVICES?</h2>
            <p>
              Choosing a digital marketing partner is more than just ticking
              boxes. It's about finding a team that cares about your growth as
              much as you do. At Twinkle Media Hub, our commitment goes beyond
              services; it's about becoming an extension of your team. From
              creative campaigns to data-driven insights, we're here to empower
              your brand and drive tangible results.
            </p>
          </div>
          <div className="determine col-md-3  col-sm-12 mt-2 px-5 px-sm-5 px-md-0">
            <h2>OUR BEST Digital Marketing Services?</h2>
            <p>
              Explore our range of digital marketing services designed to
              upgrade your brand. Whether it's captivating content creation,
              precision-targeted PPC campaigns, or engaging social media
              strategies, we bring expertise and innovation to every project.
              Discover how our holistic approach can transform your digital
              presence into a competitive advantage.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
