// import React from 'react';
 import './Loadder.css';  // Import the CSS file for styling

// const Loader = () => {
//   return (
//     <div className="logo-container">

//     <img src='assest/logo.ico' alt="Brand Logo" className="logos img-fluid" />
//     </div>
//   );
// };

// export default Loader;

// import React from 'react';
// import './Loader.css';  // Import the CSS file for styling

// const Loader = () => {
//   return (
//     <div className='logo-container'>
//     <div class="loader">
//   <span></span>
//   <span></span>
//   <span></span>
//   <span></span>
// </div>
//     </div>
//   );
// };

// export default Loader;


import React from 'react';
import './Loader.css';  // Import the CSS file for styling

const Loader = () => {
  return (
    <div className='logo-container'>

    <div className="loader">
    <span>Twinkle Media Hub</span>
    <span>Twinkle Media Hub</span>
</div>
    </div>
  );
};

export default Loader;

