const gridData = [
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/LOGOS/logo-01.png",
    "thumbnail": "consectetur",
    "category": "logos",
    "video": false,
    "name": "logo"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/LOGOS/logo-02.png",
    "thumbnail": "consectetur",
    "category": "logos",
    "video": false,
    "name": "logo"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/LOGOS/logo-03.png",
    "thumbnail": "consectetur",
    "category": "logos",
    "video": false,
    "name": "logo"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/LOGOS/logo-04.png",
    "thumbnail": "consectetur",
    "category": "logos",
    "video": false,
    "name": "logo"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/LOGOS/logo-10.png",
    "thumbnail": "consectetur",
    "category": "logos",
    "video": false,
    "name": "logo"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/LOGOS/logo-15.png",
    "thumbnail": "consectetur",
    "category": "logos",
    "video": false,
    "name": "logo"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/AI POSTS/AI-01.png",
    "thumbnail": "consectetur",
    "category": "ai-posts",
    "video": false,
    "name": "ai-post"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/AI POSTS/AI-02.png",
    "thumbnail": "consectetur",
    "category": "ai-posts",
    "video": false,
    "name": "ai-post"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/AI POSTS/AI-03.png",
    "thumbnail": "consectetur",
    "category": "ai-posts",
    "video": false,
    "name": "ai-post"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/AI POSTS/AI-04.png",
    "thumbnail": "consectetur",
    "category": "ai-posts",
    "video": false,
    "name": "ai-post"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/AI POSTS/AI-06.png",
    "thumbnail": "consectetur",
    "category": "ai-posts",
    "video": false,
    "name": "ai-post"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/AI POSTS/AI-07.png",
    "thumbnail": "consectetur",
    "category": "ai-posts",
    "video": false,
    "name": "ai-post"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/FILE/File-02.png",
    "thumbnail": "consectetur",
    "category": "brochures",
    "video": false,
    "name": "brochures"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/FILE/File-04.png",
    "thumbnail": "consectetur",
    "category": "brochures",
    "video": false,
    "name": "brochures"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/FILE/File-08.png",
    "thumbnail": "consectetur",
    "category": "brochures",
    "video": false,
    "name": "brochures"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/FILE/File-03.png",
    "thumbnail": "consectetur",
    "category": "brochures",
    "video": false,
    "name": "brochures"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/FILE/File-05.png",
    "thumbnail": "consectetur",
    "category": "brochures",
    "video": false,
    "name": "brochures"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/FILE/File-01.png",
    "thumbnail": "consectetur",
    "category": "brochures",
    "video": false,
    "name": "brochures"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/INTERACTIVE POSTS/IP-01.png",
    "thumbnail": "consectetur",
    "category": "ip",
    "video": false,
    "name": "ip"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/INTERACTIVE POSTS/IP-02.png",
    "thumbnail": "consectetur",
    "category": "ip",
    "video": false,
    "name": "ip"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/INTERACTIVE POSTS/IP-03.png",
    "thumbnail": "consectetur",
    "category": "ip",
    "video": false,
    "name": "ip"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/INTERACTIVE POSTS/IP-04.png",
    "thumbnail": "consectetur",
    "category": "ip",
    "video": false,
    "name": "ip"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/INTERACTIVE POSTS/IP-05.png",
    "thumbnail": "consectetur",
    "category": "ip",
    "video": false,
    "name": "ip"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/INTERACTIVE POSTS/IP-06.png",
    "thumbnail": "consectetur",
    "category": "ip",
    "video": false,
    "name": "ip"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/MEDICAL/med-01.png",
    "thumbnail": "consectetur",
    "category": "medical",
    "video": false,
    "name": "medical"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/MEDICAL/med-03.png",
    "thumbnail": "consectetur",
    "category": "medical",
    "video": false,
    "name": "medical"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/MEDICAL/med-04.png",
    "thumbnail": "consectetur",
    "category": "medical",
    "video": false,
    "name": "medical"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/MEDICAL/med-05.png",
    "thumbnail": "consectetur",
    "category": "medical",
    "video": false,
    "name": "medical"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/MEDICAL/med-06.png",
    "thumbnail": "consectetur",
    "category": "medical",
    "video": false,
    "name": "medical"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/MEDICAL/med-08.png",
    "thumbnail": "consectetur",
    "category": "medical",
    "video": false,
    "name": "medical"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/PACKAGING/pack-01.png",
    "thumbnail": "consectetur",
    "category": "packaging",
    "video": false,
    "name": "packaging"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/PACKAGING/pack-10.png",
    "thumbnail": "consectetur",
    "category": "packaging",
    "video": false,
    "name": "packaging"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/PACKAGING/pack-03.png",
    "thumbnail": "consectetur",
    "category": "packaging",
    "video": false,
    "name": "packaging"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/PACKAGING/pack-11.png",
    "thumbnail": "consectetur",
    "category": "packaging",
    "video": false,
    "name": "packaging"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/PACKAGING/pack-12.png",
    "thumbnail": "consectetur",
    "category": "packaging",
    "video": false,
    "name": "packaging"
  },
  {
    "_id": "66839abaa42decf3fb89167d",
    "heading2": "Colon",
    "heading4": "Carpenter",
    "heading6": "Margarita",
    "url": "asset/grid-img/PACKAGING/pack-13.png",
    "thumbnail": "consectetur",
    "category": "packaging",
    "video": false,
    "name": "packaging"
  },
  
  
]



  export default gridData;


  /*[
    '{{repeat(6)}}',
    {
      _id: '{{objectId()}}',
  heading2:"{{firstName()}}",
  heading4: "{{firstName()}}",
  heading6: "{{firstName()}}",
  url: "{{lorem(1,'word')}}",
  thumbnail: "{{lorem(1,'word')}}",
  category: '{{random ("ai-posts","brochures","ip","logos","medical","packaging")}}',
  video:"{{bool()}}",
  name: "{{firstName()}}"
  }
  ] */