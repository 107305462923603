const imagess = [
    {
      id: 1,
      image: "asset/collaboration/web-logo-1.png"
    },
    {
      id: 2,
      image: "asset/collaboration/web-logo-2.png"
    },
    {
      id: 3,
      image: "asset/collaboration/web-logo-3.png"
    },
    {
      id: 4,
      image: "asset/collaboration/web-logo-4.png"
    },
    {
      id: 5,
      image: "asset/collaboration/web-logo-5.png"
    }
    ,
    {
      id: 6,
      image: "asset/collaboration/web-logo-6.png"
    }
    ,
    {
      id: 7,
      image: "asset/collaboration/web-logo-7.png"
    }
  ];
  
  export default imagess;
  