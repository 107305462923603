import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css";
import './Team.css'
import myteam from './MyTeam'
import Heading from '../../component/Heading/Heading'
import TeamCard from '../../component/TeamCard/TeamCard';
export default function Team() {
    const [team,setTeam]=useState([])
    useEffect(()=>{
        setTeam(myteam)
    })
  const teamSetting = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initalSlide: 0,
    arrows: false,
    resposive: [

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          initalSlide: 0,
        }
      },

      {breakpoint: 600,
        settings: {slidesToShow: 1,
            slidesToScroll: 2,
            initalSlide: 0,}
      }
      
    ]
  };


    return (
        
       <div className="slick-slider container" id="team">
          <Heading headingLabel="our team" />
          <Slider {...teamSetting} responsive={teamSetting.resposive}> 
          { 
            team?.map((data)=>{
                return(
                <div class="col-md-4 col-sm-6 p-2">
                    <TeamCard name={data.name} designation={data.designation} imgUrl={data.imgUrl} />  
                </div>
                )
            })
          }
          </Slider>
        </div>
       
     
        
  )
}
